'use client'

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { memoize, omit, shuffle } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'

import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState, type CSSProperties, type ReactNode } from 'react'

import Icon from '../Icon'

import BEMHelper from '@/helpers/bem'
import useTimeout from '@/hooks/useTimeout'
import Button from '../Button'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

type Task = {
  _key: string
  title: string
  group?: string
  correct?: number
}

type StepType = {
  _key: string
  title: string
  preamble?: string
  summary?: boolean
  type: 'default' | 'sorting'
  tasks: Task[]
}

type PageData = {
  title: string
  slug: { current: string }
  content: StepType[]
  publishedAt?: string
  completed: {
    title: string
    preamble: string
  }
}

export default function InteractiveTask({ data }: { data: PageData }) {
  const router = useRouter()
  const [currentStep, setStep] = useState(0)
  const [completed, setCompleted] = useState(false)
  const step = data.content[currentStep]

  const nextStep = useCallback(() => {
    if (currentStep < data.content.length - 1) {
      setStep((val) => val + 1)
    } else {
      setStep(data.content.length - 1)
      setCompleted(true)
    }
  }, [currentStep, data.content.length])

  const back = useCallback(() => {
    if (completed) {
      setStep(data.content.length - 1)
      setCompleted(false)

      return
    }
    if (currentStep !== 0) {
      setStep((val) => val - 1)
    }
  }, [currentStep, completed, data.content.length])

  const restart = () => {
    setStep(0)
    setCompleted(false)
  }

  return (
    <div {...bem('')}>
      <nav {...bem('controls')}>
        <Button
          icon="chevron"
          iconProps={{ direction: 'left' }}
          circle
          primary
          disabled={currentStep === 0}
          onClick={back}
          {...bem('control')}
        />
        <Button
          icon="chevron"
          circle
          primary
          onClick={nextStep}
          disabled={currentStep === data.content?.length - 1}
          {...bem('control')}
        />
        <Button
          {...bem('control', 'close')}
          circle
          primary
          href={router?.query?.referer?.toString() || '/'}
          label="Lukk"
          icon="close"
        />
      </nav>
      {completed ? (
        <div {...bem('completed')}>
          <div {...bem('cover')}>
            <div {...bem('cover-content')}>
              <h1 {...bem('cover-title')}>{data?.completed?.title}</h1>
              <p {...bem('cover-preamble')}>{data?.completed?.preamble}</p>

              <Button primary {...bem('restart')} onClick={restart}>
                Start på nytt
              </Button>
            </div>

            <Image
              src="/icons/innlevering.png"
              alt=""
              {...bem('illustration')}
              width={220}
              height={220}
            />
          </div>
        </div>
      ) : (
        <>
          {step.type === 'sorting' ? (
            <SortingStep {...step} step={currentStep} onNext={nextStep} key={currentStep} />
          ) : (
            <BucketStep {...step} step={currentStep} onNext={nextStep} key={currentStep} />
          )}
        </>
      )}
    </div>
  )
}

function BucketStep({
  step,
  title,
  preamble,
  summary,
  tasks = [],
  onNext,
}: StepType & {
  step: number
  onNext: () => void
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [waitForSummary, setWaitForSummary] = useState(!!summary)
  const [list, setList] = useState<Task[]>([])
  // group id + task id
  const [values, setValues] = useState<{
    [key: string]: string
  }>({})

  const [enter, setEnter] = useState(true)

  useTimeout(() => {
    setEnter(false)
  }, 1000)

  useEffect(() => {
    setList(shuffle(tasks))
  }, [tasks])

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTo(0, 0)
    }
  }, [step])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleDragEnd(event: any) {
    const { over, active } = event

    if (active?.id) {
      setValues((values) => {
        const currentGroup = Object.entries(values).filter(([, value]) => value === active.id)
        const groupValue = values[over?.id]

        if (currentGroup?.[0]?.[0] && over?.id) {
          const groupId = currentGroup[0][0]

          return {
            ...omit(values, groupId),
            [over.id]: active.id,
            ...(groupValue ? { [groupId]: groupValue } : undefined),
          }
        }

        if (!over?.id) {
          const groupId = currentGroup?.[0]?.[0]

          return { ...omit(values, groupId) }
        }

        return { ...values, [over.id]: active.id }
      })
    }
  }

  const unsorted = list.filter((task) => !Object.values(values).includes(task._key))
  const showNextStep = !unsorted.length

  const handleNext = useCallback(() => {
    if (waitForSummary) {
      setWaitForSummary(false)
    } else {
      onNext()
    }
  }, [onNext, waitForSummary])

  return (
    <div {...bem('step')} ref={wrapperRef}>
      <header {...bem('header')}>
        <h1 {...bem('title')}>{title}</h1>
        {preamble && <p {...bem('preamble')}>{preamble}</p>}
      </header>

      <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToFirstScrollableAncestor]}>
        <div {...bem('groups')}>
          {tasks.map((bucket) => {
            const id = values[bucket._key]
            const value = tasks.find((item) => item._key === id)

            return (
              <Droppable key={bucket._key} id={bucket._key} {...bucket}>
                {value && (
                  <Draggable
                    id={value._key}
                    key={value._key}
                    {...value}
                    bucket={bucket.group}
                    showAnswer={!waitForSummary && !!summary}
                  />
                )}
              </Droppable>
            )
          })}
        </div>

        <div {...bem('unsorted', { enter })}>
          {unsorted.map((item) => (
            <Draggable id={item._key} key={item._key} {...item} type="unsorted" />
          ))}
        </div>
      </DndContext>
      {showNextStep && (
        <Footer onNext={handleNext} label={waitForSummary ? 'Sjekk svar' : 'Fortsett'} />
      )}
    </div>
  )
}

function Droppable({ id, group, children }: Task & { id: string; children?: ReactNode }) {
  const { isOver, setNodeRef } = useDroppable({ id })

  return (
    <div ref={setNodeRef} {...bem('group', { active: isOver })}>
      <h2 {...bem('group-title')}>{group}</h2>

      {children}
    </div>
  )
}

// id is for memoizing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getRandomRotation = memoize((id: string) => {
  return `${Math.random() * 8 - 4}deg`
})

function Draggable({
  type,
  title,
  id,
  showAnswer,
  bucket,
  group,
}: Task & { id: string; showAnswer?: boolean; type?: string; bucket?: string }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id })
  const style = transform
    ? { transform: `translate3d(${transform.x}px, ${transform.y}px, 0) rotate(-2deg)` }
    : (type === 'unsorted' && { rotate: getRandomRotation(id) }) || undefined

  const isCorrect = bucket === group && showAnswer
  const isWrong = bucket !== group && showAnswer

  return (
    <button
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      {...bem('item', {
        active: !!transform,
        [type || '']: type,
        correct: isCorrect,
        wrong: isWrong,
      })}
    >
      <Icon icon="dragHandle" {...bem('drag-handle')} />
      {title}

      {showAnswer && (
        <span {...bem('summary', { group: true, correct: isCorrect, wrong: isWrong })}>
          {isCorrect ? <Icon icon="check" /> : group}
        </span>
      )}
    </button>
  )
}

function Footer({ onNext, label = 'Fortsett' }: { onNext: () => void; label?: string }) {
  return (
    <footer {...bem('footer')}>
      <Button primary onClick={onNext} {...bem('next')}>
        {label}
      </Button>
    </footer>
  )
}

function SortingStep({
  step,
  title,
  preamble,
  summary,
  tasks,
  onNext,
}: StepType & {
  step: number
  onNext: () => void
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [list, setList] = useState<Task[]>([])
  const [enter, setEnter] = useState(true)
  const [waitForSummary, setWaitForSummary] = useState(!!summary)

  useTimeout(() => {
    setEnter(false)
  }, 1000)

  useEffect(() => {
    setList(shuffle(tasks))
  }, [tasks])

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTo(0, 0)
    }
  }, [step])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleDragEnd(event: any) {
    const { active, over } = event

    if (active.id !== over.id) {
      setList((items) => {
        const oldIndex = items.findIndex((item) => item._key === active.id)
        const newIndex = items.findIndex((item) => item._key === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const handleNext = useCallback(() => {
    if (waitForSummary) {
      setWaitForSummary(false)
    } else {
      onNext()
    }
  }, [onNext, waitForSummary])

  return (
    <div {...bem('step')} ref={wrapperRef}>
      <header {...bem('header')}>
        <h1 {...bem('title')}>{title}</h1>
        {preamble && <p {...bem('preamble')}>{preamble}</p>}
      </header>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <ol {...bem('sort-list', { enter })}>
          <SortableContext
            items={list.map((item) => item._key)}
            strategy={verticalListSortingStrategy}
          >
            {list.map((item, index) => (
              <SortableItem
                key={item._key}
                id={item._key}
                {...item}
                index={index}
                showAnswer={!waitForSummary && !!summary}
              />
            ))}
          </SortableContext>
        </ol>
      </DndContext>

      <Footer onNext={handleNext} label={waitForSummary ? 'Sjekk svar' : 'Fortsett'} />
    </div>
  )
}

function SortableItem({
  id,
  title,
  index,
  correct,
  showAnswer,
}: Task & { id: string; index: number; showAnswer?: boolean }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })

  const style = {
    // transform: transform ? `translateY(${transform.y}px)` : undefined, // CSS.Transform.toString(transform),
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined, // CSS.Transform.toString(transform),
    transition,
  }

  const isCorrect = correct === index + 1 && showAnswer
  const isWrong = correct !== index + 1 && showAnswer

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...bem('sort-item', { active: !!isDragging, correct: isCorrect, wrong: isWrong })}
    >
      <span {...bem('number')} key={index}>
        {index + 1}.
      </span>
      <Icon icon="dragHandle" {...bem('sort-handle')} />
      {title}

      {showAnswer && (
        <span {...bem('summary', { correct: isCorrect, wrong: isWrong })}>
          {isCorrect ? <Icon icon="check" /> : correct}
        </span>
      )}
    </li>
  )
}

type EntryProps = {
  title: string
  slug: string
  topicTheme?: {
    background: string
    promoted: string
  }
}

export function Entry({ slug, title, topicTheme }: EntryProps) {
  const router = useRouter()

  const styles: CSSProperties = {
    '--color-background': topicTheme?.background,
    '--color-promoted': topicTheme?.promoted,
  }

  return (
    <Link
      href={{ pathname: `/oppgave/${slug}`, query: { referer: router.asPath } }}
      style={styles}
      {...bem('entry')}
    >
      <Image
        src="/icons/innlevering.png"
        alt=""
        {...bem('entry-illustration')}
        width={160}
        height={160}
      />
      <span {...bem('entry-tag')}>Oppgave</span>
      <h3 {...bem('entry-title')}>{title}</h3>
    </Link>
  )
}
